import React, { useEffect } from 'react';

const AdSenseAd = ({ slotId }) => {
  useEffect(() => {
    const adsbygoogleScript = document.createElement('script');
    adsbygoogleScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    adsbygoogleScript.async = true;
    adsbygoogleScript.setAttribute('crossorigin', 'anonymous');
    document.body.appendChild(adsbygoogleScript);

    adsbygoogleScript.onload = () => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error('Error loading the AdSense script:', e);
      }
    };

    return () => {
      document.body.removeChild(adsbygoogleScript);
    };
  }, []);

  return (
    <ins className="adsbygoogle"
         style={{ display: 'block', width: '728px', height: '90px' }}
         data-ad-client="ca-pub-5184764554239245"
         data-ad-slot={slotId}></ins>
  );
};

export default AdSenseAd;
