import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <header className="header border-bottom mb-3">
            <div className="container">
                <nav className="navbar navbar-expand">
                    <Link to="/" className="navbar-brand">
                        <div style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            backgroundColor: '#f0f0f0', // Light grey background for the rounded box
                            borderRadius: '20px', // Rounded corners
                            padding: '5px 10px', // Some padding around the text and emoji
                            color: '#333', // Text color, adjust as needed
                        }}>
                            AdaneDev
                            <img src="/meEmoji.png" alt="" style={{
                                height: '24px',
                                marginLeft: '8px',
                            }} />
                        </div>
                    </Link>
                    <div className="navbar-nav">
                        <Link to="/apps" className="nav-item nav-link">Apps</Link>
                        <Link to="/dashboards" className="nav-item nav-link">Dashboards</Link>
                        <Link to="/projects" className="nav-item nav-link">Projects</Link>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
