import React from 'react';

const Home = () => {
    return (
        <div className="container my-5">
            {/* Resume Content */}
            <div className="row mb-5">
                <div className="col-12">
                    <h3 className="mb-3">Experience Highlights</h3>
                    <p><strong>KLA | Software Engineer</strong> (Sep 2022 – Aug 2023): Developed data processing pipelines and Grafana dashboards, enhancing system scalability and reliability.</p>
                    <p><strong>KLA | Automation Engineer</strong> (Apr 2021 – Sep 2022): Developed Python automated scripts for QA processes, improving development efficiency.</p>
                    <p><strong>SCE | Teaching Assistant</strong> (Oct 2020 – Sep 2021): Tutored in Computer Science, Math, and Physics, aiding in student comprehension and success.</p>

                    <h2 className="mt-4">Education</h2>
                    <p>Sami Shamoon College of Engineering (SCE), BSc in Software Engineering (Oct 2019 – Jul 2023), GPA: 90.</p>

                    <h2 className="mt-4">Skills & Abilities</h2>
                    <ul>
                        <li>Expertise in Python, SQL, Flutter, Java.</li>
                        <li>Proficient with AWS, Grafana, Jenkins.</li>
                        <li>Experienced in software engineering, testing, and data visualization.</li>
                    </ul>
                </div>
            </div>

            {/* Contact Information and Photo on the same row */}
            <div className="row">
                <div className="col-md-6">
                    <h3>Contact Information</h3>
                    <ul className="list-unstyled">
                        <li className="mb-3">
                            <img src="/icons/gmail.png" alt="Email" style={{ width: "28px", marginRight: "15px", verticalAlign: "middle" }} />
                            <a href="mailto:real.adane.adgo@gmail.com">real.adane.adgo@gmail.com</a>
                        </li>
                        <li className="mb-3">
                            <img src="/icons/location.png" alt="Location" style={{ width: "28px", marginRight: "15px", verticalAlign: "middle" }} />
                            Tel Aviv, Israel
                        </li>
                        <li className="mb-3">
                            <img src="/icons/linkedin.png" alt="LinkedIn" style={{ width: "28px", marginRight: "15px", verticalAlign: "middle" }} />
                            <a href="https://www.linkedin.com/in/adaneadgo" target="_blank" rel="noopener noreferrer">adaneadgo</a>
                        </li>
                        <li className="mb-3">
                            <img src="/icons/github.png" alt="GitHub" style={{ width: "28px", marginRight: "15px", verticalAlign: "middle" }} />
                            <a href="https://github.com/RealAdane" target="_blank" rel="noopener noreferrer">RealAdane</a>
                        </li>
                        <li className="mb-3">
                            <img src="/icons/twitter.png" alt="Twitter" style={{ width: "28px", marginRight: "15px", verticalAlign: "middle" }} />
                            <a href="https://twitter.com/RealAdane" target="_blank" rel="noopener noreferrer">RealAdane</a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <img src="/me.png" alt="Profile" className="img-fluid" style={{
                        maxWidth: '100%',
                        height: 'auto',
                        width: 'auto',
                        maxHeight: '400px',
                        boxShadow: '0 0 0 10px rgba(255, 255, 255, 0.5)' // Simulating a transparent border
                    }} />
                </div>
            </div>
        </div>
    );
};

export default Home;
