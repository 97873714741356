import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import AdSenseAd from '../Components/AdSenseAd'; // Ensure this path is correct based on your project structure

const projects = [
    {
        id: 1,
        name: "Bubbles",
        image: "/projects/bubbles.png",
        details: "A location-based social network app",
        links: [
            { name: "GitHub", url: "https://github.com/RealAdane/Bubbles" },
            { name: "YouTube", url: "https://youtu.be/ADrEeZ47ztg?si=2QHzvQNWQpcPwLzw" }
        ]
    },
    {
        id: 2,
        name: "Coronavirus Simulator",
        image: "/projects/corona.jpeg",
        details: "A simulator for studying the spread of Coronavirus",
        links: [
            { name: "GitHub", url: "https://github.com/RealAdane/Coronavirus-Simulator" }
        ]
    },
    {
        id: 3,
        name: "Pack4U",
        image: "/projects/pack4u.png",
        details: "A local data management system",
        links: [
            { name: "GitHub", url: "https://github.com/RealAdane/Pack4u" }
        ]
    },
];

const Projects = () => {
    return (
        <Container>
            {projects.map(project => (
                <Row key={project.id} className="mb-3">
                    <Col>
                        <Card className="shadow">
                            <Card.Img variant="top" src={project.image} style={{ height: '200px', objectFit: 'cover' }} />
                            <Card.Body>
                                <Card.Title>{project.name}</Card.Title>
                                <Card.Text>{project.details}</Card.Text>
                                {project.links && project.links.map(link => (
                                    <Card.Link key={link.name} href={link.url} target="_blank" rel="noopener noreferrer">{link.name}</Card.Link>
                                ))}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            ))}
            <Row className="mt-3">
                <Col>
                    <AdSenseAd slotId="4799378374" format="auto" responsive="true" />
                </Col>
            </Row>
        </Container>
    );
};

export default Projects;
