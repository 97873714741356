import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const navigationItems = [
    { id: 1, name: "Placeholder 1", icon: "🔗", link: "/apps/placeholder-1" },
    { id: 2, name: "Placeholder 2", icon: "🔗", link: "/apps/placeholder-2" },
    { id: 3, name: "Placeholder 3", icon: "🔗", link: "/apps/placeholder-3" },
    { id: 4, name: "Placeholder 4", icon: "🔗", link: "/apps/placeholder-4" },
    { id: 5, name: "Placeholder 5", icon: "🔗", link: "/apps/placeholder-5" },
    { id: 6, name: "Placeholder 6", icon: "🔗", link: "/apps/placeholder-6" },
    { id: 7, name: "Placeholder 7", icon: "🔗", link: "/apps/placeholder-7" },
    { id: 8, name: "Placeholder 8", icon: "🔗", link: "/apps/placeholder-8" },
    { id: 9, name: "Placeholder 9", icon: "🔗", link: "/apps/placeholder-9" },
];


const Applications = () => {
    return (
        <Container>
            <Row className="g-4 justify-content-center">
                {navigationItems.map((item) => (
                    <Col key={item.id} xs={6} md={4} lg={3} className="d-flex justify-content-center">
                        <Link to={item.link} className="text-decoration-none text-center">
                            <Card className="align-items-center rounded-circle text-center shadow" style={{ width: '120px', height: '120px', backgroundColor: '#f8f9fa'}}>
                                <Card.Body className="d-flex justify-content-center align-items-center flex-column">
                                    <div className="icon mb-2" style={{fontSize: '2.5rem'}}>{item.icon}</div>
                                </Card.Body>
                            </Card>
                            <div className="mt-2" style={{fontSize: '0.9rem', maxWidth: '120px'}}>{item.name}</div>
                        </Link>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Applications;
