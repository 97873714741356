import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const categories = [
    { id: 1, name: "Placeholder 1", icon: "🔗", link: "/dashboards/placeholder-1" }, 
    { id: 2, name: "Placeholder 2", icon: "🔗", link: "/dashboards/placeholder-2" },
    { id: 3, name: "Placeholder 3", icon: "🔗", link: "/dashboards/placeholder-3" }, 
    { id: 4, name: "Placeholder 4", icon: "🔗", link: "/dashboards/placeholder-4" }, 
    { id: 5, name: "Placeholder 5", icon: "🔗", link: "/dashboards/placeholder-5" }, 
    { id: 6, name: "Placeholder 6", icon: "🔗", link: "/dashboards/placeholder-6" }, 
    { id: 7, name: "Placeholder 7", icon: "🔗", link: "/dashboards/placeholder-7" }, 
    { id: 8, name: "Placeholder 8", icon: "🔗", link: "/dashboards/placeholder-8" }, 
    { id: 9, name: "Placeholder 9", icon: "🔗", link: "/dashboards/placeholder-9" }, 
];


const Dashboard = () => {
    return (
        <Container>
            <div className="dashboard-list">
                {categories.map(category => (
                    <Link to={category.link} key={category.id} className="dashboard-item">
                        <Card className="dashboard-card">
                            <Card.Body>
                                <div className="icon">{category.icon}</div>
                                <div className="name">{category.name}</div>
                            </Card.Body>
                        </Card>
                    </Link>
                ))}
            </div>
        </Container>
    );
};

export default Dashboard;
