// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './Header';
import Footer from './Footer';

import Home from './Home/Home';
import Applications from './Applications/Applications';
import Dashboards from './Dashboards/Dashboards';
import Projects from './Projects/Projects';



function App() {
  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <Header />
        <main style={{ flex: '1' }}>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/apps" element={<Applications/>} />
            <Route path="/dashboards" element={<Dashboards/>} />
            <Route path="/projects" element={<Projects/>} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
